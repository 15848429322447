.video-js {
  & .vjs-casting-button {
    height: 43px;
    width: 43px;
    margin-top: 6px;
    margin-left: 8px;
    margin-right: 8px;

    background-image: url('../assets/CastingIcon.svg');

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 1;

    @media (max-width: 860px) {
      height: 24px;
      width: 24px;
      margin-top: 3px;
    }
  }
}

.video-js {
  & .vjs-control-bar {
    background-color: rgba(0, 0, 0, 0);
    font-size: 180%;

    @media (max-width: 860px) {
      font-size: 100%;
    }
  }

  & .vjs-max-quality-selector-button {
    margin-right: 6px;
  }

  & .vjs-airplay-button {
    height: 32px;
    width: 32px;
    margin-top: 11px;
    margin-left: 22px;
    margin-right: 22px;

    background-image: url('../assets/AirplayIcon.svg');

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 1;

    @media (max-width: 860px) {
      height: 18px;
      width: 18px;
      margin-top: 5px;
      margin-right: 21px;
    }
  }
}

.video-js {
  .vjs-overlay {
    color: #fff;
    position: absolute;
    text-align: center;
  }

  .vjs-overlay-no-background {
    max-width: 33%;
  }

  .vjs-overlay-background {
    /* background-color: #646464;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: round(2.5px);
    padding: 10px;
    width: 33%; */
    background: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: left;
    padding: 6px 8px;
    color: white;
  }

  .vjs-overlay-top-left {
    top: 16px;
    left: 16px;
  }

  .vjs-overlay-top {
    left: 50%;
    margin-left: -16.5%;
    top: 5px;
  }

  .vjs-overlay-top-right {
    right: 5px;
    top: 5px;
  }

  .vjs-overlay-right {
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  .vjs-overlay-bottom-right {
    bottom: 3.5em;
    right: 5px;
  }

  .vjs-overlay-bottom {
    bottom: 3.5em;
    left: 50%;
    margin-left: -16.5%;
  }

  .vjs-overlay-bottom-left {
    bottom: 3.5em;
    left: 5px;
  }

  .vjs-overlay-left {
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  .vjs-overlay-center {
    left: 50%;
    margin-left: -16.5%;
    top: 50%;
    transform: translateY(-50%);
  }

  .vjs-no-flex .vjs-overlay-left,
  .vjs-no-flex .vjs-overlay-center,
  .vjs-no-flex .vjs-overlay-right {
    margin-top: -15px;
  }
}
